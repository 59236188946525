$(document).ready(function(){
	$(window).load(function(){
    
    $(document).on('click', '.secret-email-modal', function(e) {
      e.preventDefault();
      $('.email-modal').modal('show');
    });

		// VARIABLES
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.main-header').outerHeight(),
		$footerHeight = $('footer.footer').outerHeight(),
		$mainWrapper = $('.wrapper-holder');

		// INITIATIONS
		AOS.init();

		// CUSTOM FUNCTIONS
    stickySidebar();
    sidebar_scroll();
		display_slick();
		carouselAnimation();
		tableClass();
		mobileLayout();
		wrapperHolder( $pageHeight, $navHeight, $footerHeight );
		cf7formsubmit();
		caretMenu();
    search();

		$('.loader-overlay').fadeOut(200);

	});

	$(window).on('resize', function(){
    stickySidebar();
		// VARIABLES
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.main-header').outerHeight(),
		$footerHeight = $('footer.footer').outerHeight(),
		$mainWrapper = $('.wrapper-holder');

		wrapperHolder( $pageHeight, $navHeight, $footerHeight );

	});

});

/* MODERNIZR LAYOUT - This serves as the the media query inside the Javascript */
if( Modernizr.mq('(min-width: 1200px)') ) {
	equalizer();
}
else if( Modernizr.mq('(min-width: 992px)') && Modernizr.mq('(max-width: 1199px)') ) {
	equalizer();
}
else if( Modernizr.mq('(max-width: 991px)') && Modernizr.mq('(min-width: 768px)')){
	equalizer();
}
else{
}

// FUNCTION LISTS
/*
* Method smooth scrolls to given anchor point
*/

function pre( $object ){
	console.log( $object );
}

function smoothScrollTo(anchor) {
	var duration = 400; //time (milliseconds) it takes to reach anchor point
	var targetY = $(anchor).offset().top - 82;
	$("html, body").animate({
		"scrollTop" : targetY
	}, duration, 'easeInOutCubic');
}

function carouselAnimation(){
	/* Demo Scripts for Bootstrap Carousel and Animate.css article
	* on SitePoint by Maria Antonietta Perna
	*/
  //Function to animate slider captions 
  function doAnimations( elems ) {
    //Cache the animationend event in a variable
    var animEndEv = 'webkitAnimationEnd animationend';
    
    elems.each(function () {
      var $this = $(this),
        $animationType = $this.data('animation');
      $this.addClass($animationType).one(animEndEv, function () {
        $this.removeClass($animationType);
      });
    });
  }
  
  //Variables on page load 
  var $myCarousel = $('.carousel'),
    $firstAnimatingElems = $myCarousel.find('.item:first').find("[data-animation ^= 'animated']");
      
  //Initialize carousel 
  $myCarousel.carousel();
  
  //Animate captions in first slide on page load 
  doAnimations($firstAnimatingElems);
  
  //Pause carousel  
  $myCarousel.carousel('pause');
  
  
  //Other slides to be animated on carousel slide event 
  $myCarousel.on('slide.bs.carousel', function (e) {
    var $animatingElems = $(e.relatedTarget).find("[data-animation ^= 'animated']");
    doAnimations($animatingElems);
  });

  $('.carousel').carousel();
}

function tableClass(){
	var $tables = $(document).find('table');
	if( $tables ) {
		$tables.wrap('<div class="table-responsive"></div>');
		$tables.addClass('table');
	}
}
function wrapperHolder( $pageHeight, $navHeight, $footerHeight ){
	var $pgHeight, $nvHeight, $ftHeight;
	
	if( $pageHeight ) {
		$pgHeight = $pageHeight;
	} else {
		$pgHeight = 0;
	}

	if( $navHeight ) {
		$nvHeight = $navHeight;
	} else {
		$nvHeight = 0;
	}

	if( $footerHeight ) {
		$ftHeight = $footerHeight;
	} else {
		$ftHeight = 0;
	}

	$('.wrapper-holder').css({
		'min-height': $pgHeight,
		'padding-bottom': $ftHeight
	});
	$('.main-layout').css({
		'min-height': $pgHeight,
	});
}
function mobileLayout(){
	// MOBILE MENU LAYOUT
	$('.sidepanel .menu > .menu-item-has-children').addClass('dropdown row-size');
	$('.sidepanel .menu > .menu-item-has-children > a').each(function(){
		var $curr = $(this);
		$curr.addClass('column-top nav-title');
		$('<span class="fa fa-plus dropdown-toggle nav-control column-top" data-toggle="dropdown" style="min-height: '+ $curr.outerHeight() +'px;"></span>').insertAfter( $curr );			
	});
	$('.sidepanel .menu > .menu-item-has-children > .sub-menu').addClass('dropdown-menu');
	// MOBILE MENU
	if(!$('.sidepanel').hasClass('sidepanel-out')){
		$('.close-sidemenu').hide();
	}
	$('.mobile-menu-btn').click(function(){
		$('.sidepanel').toggleClass("sidepanel-out" );
		$('body').toggleClass("sidepanel-on" );
		$(this).toggleClass('toggle-mobile-menu');
		if(!$('.sidepanel').hasClass('sidepanel-out')){
			$('.close-sidemenu').hide();
		} else {
			$('.close-sidemenu').show();
		}
	});
	$('.close-sidemenu').click(function(){
		$('.sidepanel').toggleClass("sidepanel-out");
		$(this).hide();
	});
	$('.sidepanel li a').click(function(){
		$(this).find('.fa-plus').toggleClass('fa-minus');
	});

	// BACK TO TOP
	$('.back-to-top').hide(); // HIDE ON FIRST LOAD
	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			$('.back-to-top').fadeIn();
		} else {
			$('.back-to-top').fadeOut();
		}
	});
	$('.back-to-top a').click(function () {
		$('body,html').animate({
			scrollTop: 0
		}, 800);
		return false;
	});

	$(document).on('click', '.mobile-menu-btn', function(){
		$('.menu-button').toggleClass('active');
	});
}

function cf7formsubmit(){
	$(document).on('click', '.wpcf7-submit', function(){
		var $btn = $(this);
	  var $ajaxLoader = $('.ajax-loader');
	  
	  $btn.addClass('loading');
	  $ajaxLoader.addClass('visible');

		var sitelink = $('.usd').data('usdacct');
		document.addEventListener( 'wpcf7mailsent', function( event ) {
		  location = sitelink + '/thank-you/';
		}, false );
		document.addEventListener( 'wpcf7invalid', function( event ) {
			$btn.removeClass('loading');
		 	$ajaxLoader.removeClass('visible');
		}, false );
	});
}


function locations($country, $state, $city){
	// Sample Field
	// "select.csCountryOption", "select.csStateOption", "select.csCityOption"
	filter_country_state_city($country, $state, $city);
}

function objLightcase(){
	$('a[data-rel^=lightcase]').lightcase();
}

function responsiveSlick(){
	$('.slick-responsive').each(function(){
		var breaks = $(this).data('breakpoints').split('|'),
		breakArr = [],
		arrowsval;
		$.each(breaks, function(i, items){
			breakArr.push({
				breakpoint: parseInt(items.split(',')[0]),
	      settings: {
	        slidesToShow: parseInt(items.split(',')[1])
	      }
			});
		});
		if( $(this).data('arrows') ) {
			arrowsval = true;
		} else {
			arrowsval = false;
		}
		$(this).slick({
		  dots: false,
		  infinite: true,
		  speed: 300,
		  autoplay: true,
		  arrows: arrowsval,
		  slidesToShow: $(this).data('defaultview'),
		  slidesToScroll: 1,
		  adaptiveHeight: true,
		  asNavFor: $(this).data('thumb'),
		  responsive : breakArr,
		  prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-angle-left"></button>',
		  nextArrow : '<button type="button" class="slick-next slick-controller fa fa-angle-right"></button>',
		});
	});
}

function equalizer(){
	var maxHeight = 0;
	$(".equalize").each(function(){
	   if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
	});
	$(".equalize").height(maxHeight);

	var productHeight = 0;
	$(".hp-equalize").each(function(){
	   if ($(this).height() > productHeight) { productHeight = $(this).height(); }
	});
	$(".hp-equalize").height(productHeight);
}

function caretMenu(){
	$('.desktop-menu .nav .menu li').each( function(){
		var menu = $(this);
		if( menu.find('.sub-menu').length > 0 ) {
			menu.find('a').first().append('<span class="fas fa-angle-down"></span>');
		}
	});
}

// IOS INPUT BUG FIX
(function iOS_CaretBug() {
	var ua = navigator.userAgent,
	scrollTopPosition,
	iOS = /iPad|iPhone|iPod/.test(ua),
	iOS11 = /OS 11_0|OS 11_1|OS 11_2/.test(ua);

	// ios 11 bug caret position
 	if ( iOS && iOS11 ) {

		$(document.body).on('show.bs.modal', function(e) {
			if ( $(e.target).hasClass('modal') ) {
				// Get scroll position before moving top
				scrollTopPosition = $(document).scrollTop();

				// Add CSS to body "position: fixed"
				$("body").addClass("iosBugFixCaret");
			}
		});

     $(document.body).on('hide.bs.modal', function(e) {
			if ( $(e.target).hasClass('modal') ) {
				// Remove CSS to body "position: fixed"
				$("body").removeClass("iosBugFixCaret");

				//Go back to initial position in document
				$(document).scrollTop(scrollTopPosition);
			}
     });
   }
})();


function display_slick() {
  $('.banner-slider').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      dots: true,
      cssEase: 'linear',
      fade: true,
      autoplay: false,
  });
  $('.product-list-slider').slick({
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: false,
    dots: false,
    autoplay: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
        },
        {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
        },
        {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
        }
    ]
  });
  $('.project-list-slider').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    dots: false,
    autoplay: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
        },
        {
            breakpoint: 768,
            settings: {
            	arrows: false,
              slidesToShow: 1,
              slidesToScroll: 1,
            }
        },
        {
            breakpoint: 480,
            settings: {
            	arrows: false,
              slidesToShow: 1,
              slidesToScroll: 1,
            }
        }
    ]
  });
}

function sidebar_scroll() {
  $('.sidebar').on('click', '.sidebar-link', function(e) {
    e.preventDefault();
    var $section_id = $(this).data('section');
    smoothScrollTo('#project-'+$section_id);
  });

  $(window).scroll(function() {
      var scrollDistance = $(window).scrollTop();
      // Assign active class to nav links while scolling
      $('.projects').each(function(i) {
          if ($(this).position().top <= scrollDistance) {
              $('.sidebar a.active').removeClass('active');
              $('.sidebar a').eq(i).addClass('active');
          }
      });
  }).scroll();

  $(window).scroll(function() {
      var scrollDistance = $(window).scrollTop();
      // Assign active class to nav links while scolling
      $('.products').each(function(i) {
          if ($(this).position().top <= scrollDistance) {
              $('.sidebar a.active').removeClass('active');
              $('.sidebar a').eq(i).addClass('active');
          }
      });
  }).scroll();

  $('#mobile-sidebar').on('change', function() {
    smoothScrollTo('#project-'+$(this).val());
  });
}

function stickySidebar() {
    if( Modernizr.mq('(min-width: 768px)') ) {
      $("#sticky-sidebar").sticky({
        topSpacing: 85,
        bottomSpacing: $(".footer").outerHeight() + 20,
      });
    }
}

function search() {
  $(document).on('click', '.btn-search, .cs-backdrop', function(){
    $('.search-form, .cs-backdrop').toggleClass('active');
    $('.search-form.active input').select();
  });

  $(document).on('click', '.close-search', function(){
    $('.search-form, .cs-backdrop').toggleClass('active');
  });
  $('#searchkey').keypress(function (e) {
    if (e.which == 13) {
      $('form.search-fwrap').submit();
      return false;    //<---- Add this line
    }
  });
}